<script setup lang="ts">
import Tab from '../../../ts/models/Tab';
import {translate} from "../../../ts/helpers/translationHelper";
import {usePage} from "@inertiajs/inertia-vue3";

const props = defineProps<{
	tabs: Array<Tab>,
	useFullWidth: boolean,
	useSecondaryColor: {
		type: boolean,
		default: false,
	}
}>();

const emit = defineEmits<{
	(e: 'changeTab', tab: Tab): void
}>()

const page = usePage();

const style = ((tab) => {
	return {
		'color': (tab.current ? '#000000' : '#9ca3af'),
		'border-bottom-color': (tab.current ? (props.useSecondaryColor ? page.props.value.secondaryColor : page.props.value.primaryColor) : '#9ca3af'),
		'border-bottom-width': tab.current ? '2px' : '0',
		'border-bottom-style': 'solid'
	}
});

</script>
<template>
	<div class="pb-6">
		<div>
			<div class="block">
				<div class="border-b-2 border-gray-200 overflow-x-scroll overflow-y-hidden no-scrollbar">
					<nav class="flex" aria-label="Tabs">
						<div v-for="(tab,index) in tabs" :key="tab.index" class="flex"
						     :class="useFullWidth ? ( tabs.length === 1 ? 'w-full' : 'w-1/'+tabs.length ) : ''">
							<button
									:style="style(tab)"
									type="button"
									@click.stop="tab.disabled ? '' : emit('changeTab', tab)"
									:class="[
                      tab.disabled ? 'cursor-not-allowed' : '', 'text-center whitespace-nowrap pt-4 pb-1 font-medium text-sm',
											 'w-full px-4'
                  ]"
									:aria-current="tab.current ? 'page' : undefined">
								{{ translate(tab.titleKey) }}
							</button>
							<span  class="pt-4 text-black" v-if="!useFullWidth && index < tabs.length - 1">|</span>
						</div>
					</nav>
				</div>
			</div>
		</div>
	</div>
</template>
